<template>
  <div class="detail-bottom">
    创建时间：{{ detailData.createdTime | relativeTime }} 更新时间：{{ detailData.updatedTime | relativeTime }}
  </div>
</template>

<script>
export default {
  props: {
    detailData: {
      type: Object,
      default: () => {
        return {
          createdTime: '',
          updatedTime: '',
        }
      }
    }
  }
}
</script>

<style lang="scss">
.detail-bottom{
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  text-align: right;
  z-index: 1;
}
</style>
