<template>
  <a-drawer :visible="visible" @close="close" width="50%" :mask-closable="false"
            :get-container="false" :closable="false"
            :wrap-style="{ position: 'absolute' }"
            :header-style="{position: 'sticky', top: 0, zIndex: 100,}">
    <div slot="title" class="title-container">
      <a-page-header title="栏目管理" :sub-title="edit ? '编辑' : '详情'" style="border: none;width: 100%;padding: 8px 0;">
        <template slot="extra">
          <a-button v-if="edit" type="primary"
                    :loading="formLoading" icon="check" @click="handleSave">保存</a-button>
          <a-button v-else type="primary" icon="form" @click="toEdit">编辑</a-button>
          <a-button icon="close" @click="close">取消</a-button>
        </template>
      </a-page-header>
    </div>
    <div>
      <a-form v-if="edit" :form="form" layout="vertical">
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="所属网站">
              <a-select
                  v-decorator="['websiteId', {}, ]"
                  :options="websiteOptions"
                  disabled
                  :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
              />
            </a-form-item>
          </a-col>
          <a-col v-if="!!parentData.id" :span="24">
            <a-form-item label="上级栏目">
              <a-tree-select
                  v-decorator="['parentId', {}, ]"
                  :tree-data="columnOptions"
                  :replace-fields="{ title: 'name', key: 'id', value: 'id' }"
                  disabled
                  tree-default-expand-all
                  :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
              />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="栏目图标">
              <single-uploader />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="链接名称">
              <a-input v-decorator="['title', { rules: [{ required: true, message: '请输入链接名称!' }] }, ]"
                       :placeholder="`请输入链接名称`" />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="链接地址">
              <a-input v-decorator="['url', { rules: [{ required: true, message: '请输入链接地址!' }] }, ]"
                       :placeholder="`请输入链接地址`" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <template v-else>
        <a-descriptions layout="vertical" bordered>
          <a-descriptions-item v-for="field in detailFields" :key="field.dataIndex" :label="field.title">
            {{ detailData[field.dataIndex] }}
          </a-descriptions-item>
        </a-descriptions>
        <detail-footer :detail-data="detailData"/>
      </template>
    </div>
  </a-drawer>
</template>

<script>
import {detail, save} from "@/api/service/link";
import {isEmpty} from "lodash";
import {formFieldHandler} from "@/utils/form";
import {columns} from "@/views/link/options";
import {detailFieldHandler} from "@/utils/detail";
import DetailFooter from "@/components/ActDrawer/DetailFooter.vue";
import SingleUploader from "@/components/SingleUploader/index.vue";
import {mapActions, mapGetters} from "vuex";

export default {
  components: {SingleUploader, DetailFooter},
  data() {
    return {
      visible: false,
      edit: false,
      formFields: formFieldHandler(columns),
      detailFields: detailFieldHandler(columns),
      form: null,
      formLoading: false,
      detailData: {},
      parentData: {},
    }
  },
  computed: {
    ...mapGetters({
      websiteOptions: 'selection/websiteOptions',
      columnOptions: 'selection/columnOptions',
    })
  },
  methods: {
    ...mapActions({
      loadWebsiteOptions: 'selection/loadWebsiteOptions',
      loadLinkOptions: 'selection/loadLinkOptions',
    }),
    open({edit, record, addChild, websiteId}) {
      this.visible = true
      this.edit = edit
      if(!isEmpty(record)) {
        if(addChild) {
          this.parentData = record
        } else {
          this.detailData = record
        }
      }
      if (edit && !isEmpty(this.detailData)) {
        this.recoveryData()
      }
      this.recoveryWebsiteData(websiteId)
    },
    close() {
      this.visible = false
    },
    toEdit() {
      this.edit = true
      this.recoveryData()
    },
    async recoveryWebsiteData(websiteId) {
      await this.loadWebsiteOptions()
      if(!isEmpty(this.websiteOptions)) {
        const target = this.websiteOptions.find(item => item.id === this.detailData.websiteId || item.id === websiteId)
        this.form.setFieldsValue({
          websiteId: target.id,
        })
        this.recoveryLinkData(target.id)
      }
    },
    async recoveryLinkData(websiteId) {
      await this.loadLinkOptions({websiteId})
      this.form.setFieldsValue({
        parentId: this.parentData.id,
      })
    },
    recoveryData() {
      this.$nextTick(() => {
        this.form.setFieldsValue({
          title: this.detailData.title,
          url: this.detailData.url,
        })
      })
    },
    async reloadDetail(result) {
      const { success, data } = await detail({id: this.detailData.id || result.id})
      if(success) {
        this.detailData = data
      }
    },
    handleSave() {
      this.form.validateFields((err, values) => {
        if (!err) {
          this.doSave(values)
        }
      })
    },
    async doSave(values) {
      const target = this.websiteOptions.find(item => item.id === values.websiteId)
      const params = {
        ...values,
        websiteName: target.name,
        websiteDomain: target.domain,
      }
      if(!isEmpty(this.parentData)) {
        params['parentId'] = this.parentData.id
      }
      if (this.detailData.id) {
        params['id'] = this.detailData.id
      }
      this.formLoading = true
      const {success, data} = await save(params)
      if (success) {
        this.$notification.success({
          message: '操作成功',
          duration: 2,
          onClose: () => {
            this.reloadDetail(data)
            this.edit = false
            this.formLoading = false
            this.$emit('success')
          }
        });
      }
    }
  },
  mounted() {
    this.form = this.$form.createForm(this, {name: 'column_form'})
  }
}
</script>
