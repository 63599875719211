<template>
  <div>
    <a-row type="flex" align="middle">
      <a-col :span="24" :md="12">
        <h5 class="font-semibold m-0">站点</h5>
      </a-col>
      <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
        <a-button icon="reload"
                  :websiteLoading="websiteLoading"
                  @click="loadData" />
      </a-col>
    </a-row>
    <a-spin :spinning="websiteLoading">
      <div class="website-container">
        <div class="website-item" v-for="website in websiteOptions"
             :class="{'activity': activityWebsite.id === website.id}"
             :key="website.id"
             @click="handleWebsiteChange(website)">
          <a-avatar shape="square" :src="website.icon" />
          <div class="website-item-content">
            <div class="website-item-title">{{ website.name }}</div>
          </div>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {isEmpty} from "lodash";

export default {
  data() {
    return {
      websiteLoading: false,
      activityWebsite: {},
    }
  },
  computed: {
    ...mapGetters({
      websiteOptions: 'selection/websiteOptions',
    })
  },
  methods: {
    ...mapActions({
      loadWebsiteOptions: 'selection/loadWebsiteOptions',
    }),
    handleWebsiteChange(website) {
      this.activityWebsite = website
      this.$emit('change', this.activityWebsite)
    },
    async loadData() {
      this.websiteLoading = true
      await this.loadWebsiteOptions()
      this.websiteLoading = false
      if (!isEmpty(this.websiteOptions)) {
        if (isEmpty(this.activityWebsite)) {
          this.handleWebsiteChange(this.websiteOptions[0])
        } else {
          this.handleWebsiteChange(this.websiteOptions.find(item => item.id === this.activityWebsite.id))
        }
        this.$emit('change', this.activityWebsite)
      }
    }
  },
  mounted() {
    this.loadData()
  }
}
</script>

<style lang="scss">
.website-container{
  min-height: 300px;
}
.website-item{
  display: flex;
  align-items: center;
  padding: 12px 16px;
  cursor: pointer;
  //box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  background-color: #f0f5ff;
  margin-top: 12px;
  &:hover,
  &.activity{
    background-color: #d6e4ff;
  }
  .website-item-content{
    padding-left: 12px;
  }
}
</style>
