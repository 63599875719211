<template>
  <a-upload
      name="file"
      list-type="picture-card"
      class="avatar-uploader"
      :show-upload-list="false"
      :action="actionURL"
      :headers="headers"
      :before-upload="beforeUpload"
      @change="handleChange"
  >
    <img v-if="imageUrl" :src="imageUrl" alt="avatar"/>
    <div v-else>
      <a-icon :type="loading ? 'loading' : 'plus'"/>
      <div class="ant-upload-text">
        Upload
      </div>
    </div>
  </a-upload>
</template>

<script>
import {mapGetters} from "vuex";
import {isEmpty} from "lodash";
import {OSS} from "@/api/service/oss";
import {getBase64} from "@/utils/form";

export default {
  name: 'SingleUploader',
  data() {
    return {
      actionURL: OSS.upload,
      loading: false,
      imageUrl: '',
    };
  },
  computed: {
    ...mapGetters({
      token: 'auth/token'
    }),
    headers() {
      const headers = {}
      if(!isEmpty(this.token)) {
        headers['Authorization'] = `Bearer ${this.token}`
      }
      return headers
    }
  },
  methods: {
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.loading = true;
        return;
      }
      console.log(info);
      if (info.file.status === 'done') {
        // Get this url from response in real world.
        getBase64(info.file.originFileObj, imageUrl => {
          this.imageUrl = imageUrl;
          this.loading = false;
        });
      }
    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        this.$message.error('You can only upload JPG file!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error('Image must smaller than 2MB!');
      }
      return isJpgOrPng && isLt2M;
    },
    getData() {
      return this.imageUrl
    }
  },
};
</script>

<style>
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}

.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
